/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */
import React from "react";
import "bootswatch/dist/slate/bootstrap.min.css";
// any CSS you import will output into a single css file (app.css in this case)
import './app.css';
const $ = require('jquery');
import 'bootstrap';
import ReactDOM from "react-dom";
import {getWindowDimension} from "../../library/tools/sizeScreen";

// Need jQuery? Install it with "yarn add jquery", then uncomment to import it.

// this "modifies" the jquery module: adding behavior to it
// the bootstrap module doesn't export/return anything

// or you can include specific pieces
// require('bootstrap/js/dist/tooltip');
// require('bootstrap/js/dist/popover');
/*    const sizeScreen = getWindowDimension();
    console.log(sizeScreen.orientation);
    if (sizeScreen.orientation !== 90 ) {
        document.body.style.maxWidth = sizeScreen.width + "px";
    } else {
        document.body.style.maxWidth = sizeScreen.height + "px";
    }


window.addEventListener('resize', function() {
    //sizeWindowChange();
    const sizeScreen = getWindowDimension();
    console.log(sizeScreen.orientation);
    if (sizeScreen.orientation !== 90 ) {
        document.body.style.maxWidth = sizeScreen.width + "px";
    } else {
        document.body.style.maxHeight = sizeScreen.height + "px";
    }
});*/

/*function sizeWindowChange() {
    const sizeScreen = getWindowDimension();
    if (sizeScreen.orientation !== this.state.sizeScreen.orientation) {
        console.log("Renversement");
        this.setState({
            widthVideo: sizeScreen.width,
        });
        if (this.state.sizeScreen.orientation !== 90) {
            console.log("Renversement <> 90");
            widthVideo = this.state.sizeScreen.width;
            classOverlay = "display-4";
            classTitle = "display-1";
            this.setState({
                sizeScreen:{
                    width: this.state.sizeScreen.width,
                    height: this.state.sizeScreen.height,
                    orientation : 90
                }
            })
        } else {
            console.log("Renversement à 90");
            widthVideo = this.state.sizeScreen.height;
            classOverlay = "display-5";
            classTitle = "display-2";
            this.setState({
                sizeScreen:{
                    width: this.state.sizeScreen.width,
                    height: this.state.sizeScreen.height,
                    orientation : 0
                }
            })
        }
    } else {
        console.log("Just resize");
        this.setState({
                sizeScreen: sizeScreen
            }
        )
        if (this.state.sizeScreen.orientation !== 90) {
            widthVideo = this.state.sizeScreen.width;
            classOverlay = "display-4";
            classTitle = "display-1";
        } else {
            widthVideo = this.state.sizeScreen.height;
            classOverlay = "display-5";
            classTitle = "display-2";
        }
        classOverlay = "display-5";
        classTitle = "display-2";
    }

    heightVideo = widthVideo * 108 / 192;
    overlayStyle= {width: widthVideo + "px", height: heightVideo + "px"};
    this.setState (
        {
            widthVideo: widthVideo,
            heightVideo: heightVideo,
            overlayStyle: overlayStyle,
            classOverlay: classOverlay,
            classTitle: classTitle,
        }
    );
    console.log(this.state);
    console.log('***************************************');
}*/

$(document).ready(function() {
    $('[data-toggle="popover"]').popover();
});


